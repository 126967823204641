.mat-subtitle-2,
.mat-subtitle-1,
.mat-headline-5,
.mat-headline-6,
.mat-subtitle-1,
.mat-subtitle-2,
.mat-body-1,
.mat-body-2 {
  color: $textPrimary;
}

// Use the default configuration.
$theme-typography: mat.m2-define-typography-config(
  $font-family: "Plus Jakarta Sans",
  $subtitle-2: mat.m2-define-typography-level(14px, 14px, 400),
  $subtitle-1: mat.m2-define-typography-level(21px, 26px, 500),
  $headline-6: mat.m2-define-typography-level(18px, 26px, 500),
  $headline-5: mat.m2-define-typography-level(24px, 30px, 700),
  $button: mat.m2-define-typography-level(14px, 14px, 400),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400),
  $body-2: mat.m2-define-typography-level(16px, 22px, 400),
);

.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}

.mat-headline-5 {
  @include mat.m2-typography-level($theme-typography, "headline-5");
  margin: 0;
  color: $textPrimary;
}

.mat-headline-6 {
  @include mat.m2-typography-level($theme-typography, "headline-6");
  margin: 0;
  color: $textPrimary;
}

.mat-subtitle-2 {
  @include mat.m2-typography-level($theme-typography, "subtitle-2");
  margin: 0;
  color: $textPrimary;
}

.mat-subtitle-1 {
  @include mat.m2-typography-level($theme-typography, "subtitle-1");
  margin: 0;
  color: $textPrimary;
}

.mat-body-1 {
  @include mat.m2-typography-level($theme-typography, "body-1");
  margin: 0;
  color: $textPrimary;
}

.mat-body-2 {
  @include mat.m2-typography-level($theme-typography, "body-2");
  margin: 0;
  color: $textPrimary;
}

.mdc-list-item__primary-text,
.mat-drawer-container,
.mat-drawer {
  color: $textPrimary;
}
