.container {
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  &.full-width {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.demos-dd {
  max-width: 1200px !important;
}

.img-overlay {
  overflow: auto;
  position: relative;
  border-radius: 9px 9px 0 0;
  img {
    margin-bottom: -4px;
  }
  .overlay-content {
    display: none;
  }
  &:hover {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 8;
      background-color: rgba(0, 133, 219, 0.3);
    }
    .overlay-content {
      display: block;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      right: 50%;
      top: 50%;
      min-width: 115px;
      z-index: 9;
    }
  }
}

.topbar-xl {
  height: 100px !important;
  background-color: $background !important;
  box-shadow: none !important;
}

// ----------------------------------------------------------
// banner
// ----------------------------------------------------------
.banner-section {
  padding: 80px 0;
}

@media (min-width: 900px) {
  .banner-title {
    font-size: 54px !important;
    line-height: 60px !important;
  }
}

@media (max-width: 1200px) {
  .banner-section {
    padding-top: 60px;
  }
}

.banner-title {
  font-size: 36px;
  line-height: 44px;
}

.banner-subtitle {
  line-height: 25px;
}

.btn-custom.mdc-button {
  height: 56px !important;
  padding: 0 52px !important;
  font-size: 16px;
  border-radius: 30px !important;
  &.sm {
    padding: 0 34px !important;
  }
}

@media (max-width: 767px) {
  .btn-custom.mdc-button {
    padding: 0 36px !important;
  }
}

.slider-wrapper {
  .slider-img {
    animation: 45s linear 0s infinite normal none running slide;
  }
}

@keyframes slide {
  0% {
    transform: "translate3d(0px, 0px, 0px)";
  }
  100% {
    transform: "translate3d(-100%, 0px, 0px)";
  }
}

// sections

.demos-card {
  border: 1px solid $background;
  border-radius: $border-radius;
}

.spacer {
  padding: 80px 0 100px;
}

.section-title {
  font-size: 28px;
  line-height: 32px;
}

@media (min-width: 991px) {
  .section-title {
    font-size: 36px;
    line-height: 43px;
  }
}

.bg-extra-light {
  background-color: $hoverBgColor;
}

// demo slider
.demo-slider {
  margin-top: 40px;
  .demo-slide {
    animation: slide 45s linear infinite;
  }
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.icon-50 {
  height: 50px;
  width: 50px;
}

.bg-dark {
  background-color: #111c2d;
}

.lh-base {
  line-height: 1.75;
}

// call to action

.spacer-sm {
  padding-top: 60px;
  padding-bottom: 30px;
}

.spacer-bottom {
  padding-bottom: 60px;
}

.section-title2 {
  font-size: 36px;
  line-height: 56px;
  font-weight: 600;
}

.c2a-img {
  position: absolute;
  right: -40px;
  bottom: -32px;
}

.review-subtext {
  font-size: 36px;
  line-height: 43px;
}

.border-white {
  border: 1px solid $white !important;
}

.border-accent {
  border: 1px solid $accent !important;
}

.border-primary {
  border: 1px solid $primary !important;
}

.border-success {
  border: 1px solid $success !important;
}

.border-warning {
  border: 1px solid $warning !important;
}

.border-error {
  border: 1px solid $error !important;
}

.hstack {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 767px) {
  .hstack {
    flex-direction: column;
  }
  .bg-size-cover {
    background-size: cover;
  }
}

@media (max-width: 1023px) {
  .review-subtext,
  .section-title2 {
    font-size: 30px;
    line-height: 44px;
  }
}
